<template>
  <div class="flex flex-col w-full">
    <div
      v-if="isTitleVisible"
      class="mb-2 form-field__required inter text-lg font-medium text-neutral-900"
    >
      {{ t('organisation.form.city.label') }}
    </div>
    <auto-suggest
      v-model:query="queryString"
      v-model:selected="selectedOptions"
      :suggestions="suggestionOptions"
      :is-hide-headers="true"
      :is-fetching="isFetching"
      :close-on-select="closeOnSelect"
      :input-props="{invalidFeedback: errorMessage ? errorMessage : null, state: errorMessage ? false : null, placeholder: placeholder || t('organisation.form.city.placeholder.label')}"
      :disabled="disabled"
      :multiple="multiple"
      @select="selectedOptions = $event"
    >
      <template #option="{option}">
        {{ option.fullAddress }}
      </template>
    </auto-suggest>
  </div>
</template>
<script setup lang="ts">
import AutoSuggest from '~/components/common/AutoSuggest/AutoSuggest.vue'
import { OrganisationType } from '~/common/types/organisation/Organisation.type'
import { Address } from '~/common/types/address/Address'
import { AddressService } from '~/modules/address/service/AddressService'
import { TOPONYM_LEVEL } from '~/modules/address/constants/Adress.toponymLevel'

const props = defineProps({
  code: {
    type: String,
    required: true,
  },
  modelValue: {
    type: Object as PropType<Address | null>,
    required: true,
  },
  errorMessage: {
    type: String as PropType<string | null>,
    default: null,
  },
  closeOnSelect: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '',
  },
  isTitleVisible: {
    type: Boolean,
    default: true,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
})

type Emits = {
  (e: 'update:modelValue', value: Address | null): void,
  (e: 'setErrors', value: Record<string, any>): void
}
const emits = defineEmits<Emits>()

const { t } = useI18n()
const addressService = new AddressService()

const isFetching = ref(false)
const queryString = ref(props.modelValue?.fullAddress ?? '')
const cities = ref<Array<Address>>([])

const suggestionOptions = computed(() => {
  const suggestions = cities.value
  if (!suggestions.length) {
    return [] as Array<Record<string, any>>
  }

  return [{ cities: suggestions }]
})
const selectedOptions = computed({
  get() {
    return [{ cities: [props.modelValue] }]
  },
  set(address: Address | null) {
    emits('update:modelValue', address)
  },
})

const getSuggestions = () => {
  if (queryString.value === '' && queryString.value === null) {
    cities.value = []
    selectedOptions.value = [{ cities: [] }]
    return
  }
  isFetching.value = true
  if (queryString.value.trim()) {
    addressService.internationalSuggest(
      {
        countries: [props.code],
        highestToponym: TOPONYM_LEVEL.locality,
        language: 'ru',
        lowestToponym: TOPONYM_LEVEL.locality,
        query: queryString.value,
        searchInsideAddress: null,
      },
    )
      .then(response => {
        cities.value = response
      })
      .catch(error => {
        if (error?.data?.errors) {
          emits('setErrors', error.data.errors)
        }
      })
      .finally(() => {
        isFetching.value = false
      })
  }
}

watch(queryString, () => getSuggestions())
watch(() => props.code, () => {
  getSuggestions()
})
watch(() => props.modelValue, () => {
  queryString.value = props.modelValue?.fullAddress ?? ''
})

</script>
