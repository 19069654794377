import { HttpApiService } from '~/services/http/HttpApiService'
import { HttpHandledResponse } from '~/services/http/types/Http.handledResponse'
import { Service } from '~/services/http/Service'
import { Organisation } from '~/common/types/organisation/Organisation'
import type { User } from '~/modules/user/types/user'
import { ORGANISATION_USER_STATUS } from '~/modules/organisation/constants/OrganisationUserStatus'
import { ORGANISATION_USER_ROLES } from '~/modules/organisation/constants/OrganisationUserRoles'

export class OrganisationHttpApiService extends Service {
  private endPoints = {
    firstPart: '/v4/organisations',
    secondPart: '/v4/estate-market/organisations',
    users: '/users',
    setOrganisationProfileData: '/init',
    updateStatus: '/update-status',
    saveRole: '/save-role',
    changeInvitationSecret: '/regenerate-invitation-secret',
  }

  constructor(httpInstance: HttpApiService) {
    super(httpInstance)
  }

  setOrganisationProfileData<OrganisationResponse>(organisation: Organisation): Promise<HttpHandledResponse<OrganisationResponse>> {
    return this.httpInstance.post<OrganisationResponse>(`${this.endPoints.firstPart}/${organisation.id}${this.endPoints.setOrganisationProfileData}`, organisation)
  }

  updateOrganisation(organisation: Organisation) {
    return this.httpInstance.put<Organisation>(`${this.endPoints.secondPart}/${organisation.id}`, organisation)
  }

  setUserOrganisation(organisation: Organisation, user: User) {
    return this.httpInstance.put<User>(`${this.endPoints.secondPart}/${organisation.id}${this.endPoints.users}/${user.id}`, user)
  }

  changeUserOrganisationRole(organisation: Organisation, usersIds: number[], role: typeof ORGANISATION_USER_ROLES[keyof typeof ORGANISATION_USER_ROLES]) {
    return this.httpInstance.post<User>(`${this.endPoints.secondPart}/${organisation.id}${this.endPoints.users}${this.endPoints.saveRole}`, {
      role,
      usersIds,
    })
  }

  fireUserOrganisation(organisation: Organisation, user: User) {
    return this.httpInstance.post<User>(`${this.endPoints.secondPart}/${organisation.id}${this.endPoints.users}/${user.id}${this.endPoints.updateStatus}`, { status: ORGANISATION_USER_STATUS.fired })
  }

  changeInvitationSecret(organisation: Organisation) {
    return this.httpInstance.post<any>(`${this.endPoints.secondPart}/${organisation.id}${this.endPoints.changeInvitationSecret}`)
  }
}
