import QuickDealHttpApiService from '~/services/http/quick-deal/QuickDealHttpApiService'
import { OrganisationHttpApiService } from '~/modules/organisation/api/OrganisationHttpApiService'
import { Organisation } from '~/common/types/organisation/Organisation'
import useOrganisationStore from '~/modules/organisation/store'
import { InitAppData } from '~/common/types/app/init'
import SentryLogger from '~/services/sentry/SentryLogger'
import { User } from '@sentry/vue'
import { ORGANISATION_USER_ROLES } from '~/modules/organisation/constants/OrganisationUserRoles'

export class OrganisationService {
  http: OrganisationHttpApiService

  httpInstance: QuickDealHttpApiService

  private store: ReturnType<typeof useOrganisationStore>

  constructor() {
    const nuxtApp = useNuxtApp()
    this.httpInstance = nuxtApp.$qdHttpApiInstance
    this.http = new OrganisationHttpApiService(this.httpInstance)
    this.store = useOrganisationStore()
  }

  public setOrganisation(organisation: Organisation): Promise<Organisation> {
    return new Promise((resolve, reject) => {
      this.http.setOrganisationProfileData<Organisation>(organisation)
        .then(response => {
          this.store.setOrganisation(response.data)
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось присвоить организацию',
            organisation,
          })
          reject(error)
        })
    })
  }

  public updateOrganisation(organisation: Organisation): Promise<Organisation> {
    return new Promise((resolve, reject) => {
      this.http.updateOrganisation<Organisation>(organisation)
        .then(response => {
          this.store.setOrganisation(response.data)
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось обновить организацию',
            organisation,
          })
          reject(error)
        })
    })
  }

  public setUserOrganisation(organisation: Organisation, user: User): Promise<User> {
    return new Promise((resolve, reject) => {
      this.http.setUserOrganisation<InitAppData['user']>(organisation, user)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось обновить информацию об пользователе в организации',
            organisation,
            user,
          })
          reject(error)
        })
    })
  }

  public changeUserOrganisationRole(organisation: Organisation, usersIds: number[], role: typeof ORGANISATION_USER_ROLES[keyof typeof ORGANISATION_USER_ROLES]): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.changeUserOrganisationRole(organisation, usersIds, role)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось обновить роль пользователя в организации',
            organisation,
            usersIds,
            role,
          })
          reject(error)
        })
    })
  }

  public fireUserOrganisation(organisation: Organisation, user: User): Promise<User> {
    return new Promise((resolve, reject) => {
      this.http.fireUserOrganisation<User>(organisation, user)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось уволить пользователе из организации',
            organisation,
            user,
          })
          reject(error)
        })
    })
  }

  public changeInvitationSecret(organisation: Organisation): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.changeInvitationSecret<any>(organisation)
        .then(response => {
          this.store.setInvitationSecret(response.data)
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось сменить ссылку на приглашение в организацию',
            organisation,
          })
          reject(error)
        })
    })
  }
}
