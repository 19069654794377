<template>
  <custom-autocomplete
    v-model="country"
    :label="t('organisation.form.country.label')"
    :options="organisationCountryOptions"
    :invalid-feedback="errorMessage"
    option-label="label"
    value-label="value"
    required
    :disabled="disabled"
    @select="selectedOptions = $event"
  />
</template>
<script setup lang="ts">
import { Country } from '~/modules/address/types'
import { NULL_OPTION } from '~/ui/selects/constants/Select.nullOption'
import { useField } from 'vee-validate'
import { Countries } from '~/common/constants/country/Countries'
import CustomAutocomplete from '~/ui/selects/CustomAutocomplete.vue'

const props = defineProps({
  modelValue: {
    type: Object as PropType<Country['ISO']> | null,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})
const { t } = useI18n()

const { value: country, errorMessage } = useField<Country['ISO'] | null>('country', { required: true }, {
  label: t('organisation.form.country.label'),
  initialValue: props.modelValue,
})

const emits = defineEmits<{(e: 'update:modelValue', value: Country['ISO']): void }>()

const countriesOptions = Object.values(Countries)
  .map(value => ({
    label: value.name,
    value: value.ISO,
  }))

const organisationCountryOptions = [NULL_OPTION.value, ...countriesOptions]

const selectedOptions = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  },
})

</script>
