<template>
  <custom-autocomplete
    v-model="typeValue"
    :label="$t('organisation.form.type.label')"
    :placeholder="$t('organisation.form.type.label')"
    :invalid-feedback="errorMessage"
    :options="options"
    option-label="label"
    value-label="value"
    required
    :disabled="disabled"
  />
</template>
<script setup lang="ts">
import CustomAutocomplete from '~/ui/selects/CustomAutocomplete.vue'
import { OrganisationType } from '~/common/types/organisation/Organisation.type'
import { ORGANISATION_TYPE } from '~/common/constants/organisation/Organisation.type'
import { useField } from 'vee-validate'

defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
})
const emits = defineEmits<{(e: 'update:modelValue', value: OrganisationType | null): void }>()

const { t } = useI18n()

const { value: typeValue, errorMessage } = useField<OrganisationType | null>('type', { required: true }, {
  label: t('organisation.form.type.label'),
})

const options = Object.values(ORGANISATION_TYPE).map(value => ({
  value,
  label: t(`organisation.types.${value}.label`),
}))
</script>
