export const Countries = {
  AF: { name: 'Afghanistan', ISO: 'AF' },
  AL: { name: 'Albania', ISO: 'AL' },
  DZ: { name: 'Algeria', ISO: 'DZ' },
  AS: { name: 'American Samoa', ISO: 'AS' },
  AD: { name: 'Andorra', ISO: 'AD' },
  AO: { name: 'Angola', ISO: 'AO' },
  AI: { name: 'Anguilla', ISO: 'AI' },
  AQ: { name: 'Antarctica', ISO: 'AQ' },
  AG: { name: 'Antigua and Barbuda', ISO: 'AG' },
  AR: { name: 'Argentina', ISO: 'AR' },
  AM: { name: 'Armenia', ISO: 'AM' },
  AW: { name: 'Aruba', ISO: 'AW' },
  AU: { name: 'Australia', ISO: 'AU' },
  AT: { name: 'Austria', ISO: 'AT' },
  AZ: { name: 'Azerbaijan', ISO: 'AZ' },
  BS: { name: 'Bahamas', ISO: 'BS' },
  BH: { name: 'Bahrain', ISO: 'BH' },
  BD: { name: 'Bangladesh', ISO: 'BD' },
  BB: { name: 'Barbados', ISO: 'BB' },
  BY: { name: 'Belarus', ISO: 'BY' },
  BE: { name: 'Belgium', ISO: 'BE' },
  BZ: { name: 'Belize', ISO: 'BZ' },
  BJ: { name: 'Benin', ISO: 'BJ' },
  BM: { name: 'Bermuda', ISO: 'BM' },
  BT: { name: 'Bhutan', ISO: 'BT' },
  BO: { name: 'Bolivia', ISO: 'BO' },
  BQ: { name: 'Bonaire', ISO: 'BQ' },
  BA: { name: 'Bosnia and Herzegovina', ISO: 'BA' },
  BW: { name: 'Botswana', ISO: 'BW' },
  BV: { name: 'Bouvet Island', ISO: 'BV' },
  BR: { name: 'Brazil', ISO: 'BR' },
  IO: { name: 'British Indian Ocean Territory', ISO: 'IO' },
  VG: { name: 'British Virgin Islands', ISO: 'VG' },
  BN: { name: 'Brunei', ISO: 'BN' },
  BG: { name: 'Bulgaria', ISO: 'BG' },
  BF: { name: 'Burkina Faso', ISO: 'BF' },
  BI: { name: 'Burundi', ISO: 'BI' },
  KH: { name: 'Cambodia', ISO: 'KH' },
  CM: { name: 'Cameroon', ISO: 'CM' },
  CA: { name: 'Canada', ISO: 'CA' },
  CV: { name: 'Cape Verde', ISO: 'CV' },
  KY: { name: 'Cayman Islands', ISO: 'KY' },
  CF: { name: 'Central African Republic', ISO: 'CF' },
  TD: { name: 'Chad', ISO: 'TD' },
  CL: { name: 'Chile', ISO: 'CL' },
  CN: { name: 'China', ISO: 'CN' },
  CX: { name: 'Christmas Island', ISO: 'CX' },
  CC: { name: 'Cocos [Keeling] Islands', ISO: 'CC' },
  CO: { name: 'Colombia', ISO: 'CO' },
  KM: { name: 'Comoros', ISO: 'KM' },
  CK: { name: 'Cook Islands', ISO: 'CK' },
  CR: { name: 'Costa Rica', ISO: 'CR' },
  HR: { name: 'Croatia', ISO: 'HR' },
  CU: { name: 'Cuba', ISO: 'CU' },
  CW: { name: 'Curacao', ISO: 'CW' },
  CY: { name: 'Cyprus', ISO: 'CY' },
  CZ: { name: 'Czech Republic', ISO: 'CZ' },
  CD: { name: 'Democratic Republic of the Congo', ISO: 'CD' },
  DK: { name: 'Denmark', ISO: 'DK' },
  DJ: { name: 'Djibouti', ISO: 'DJ' },
  DM: { name: 'Dominica', ISO: 'DM' },
  DO: { name: 'Dominican Republic', ISO: 'DO' },
  TL: { name: 'East Timor', ISO: 'TL' },
  EC: { name: 'Ecuador', ISO: 'EC' },
  EG: { name: 'Egypt', ISO: 'EG' },
  SV: { name: 'El Salvador', ISO: 'SV' },
  GQ: { name: 'Equatorial Guinea', ISO: 'GQ' },
  ER: { name: 'Eritrea', ISO: 'ER' },
  EE: { name: 'Estonia', ISO: 'EE' },
  ET: { name: 'Ethiopia', ISO: 'ET' },
  FK: { name: 'Falkland Islands', ISO: 'FK' },
  FO: { name: 'Faroe Islands', ISO: 'FO' },
  FJ: { name: 'Fiji', ISO: 'FJ' },
  FI: { name: 'Finland', ISO: 'FI' },
  FR: { name: 'France', ISO: 'FR' },
  GF: { name: 'French Guiana', ISO: 'GF' },
  PF: { name: 'French Polynesia', ISO: 'PF' },
  TF: { name: 'French Southern Territories', ISO: 'TF' },
  GA: { name: 'Gabon', ISO: 'GA' },
  GM: { name: 'Gambia', ISO: 'GM' },
  GE: { name: 'Georgia', ISO: 'GE' },
  DE: { name: 'Germany', ISO: 'DE' },
  GH: { name: 'Ghana', ISO: 'GH' },
  GI: { name: 'Gibraltar', ISO: 'GI' },
  GR: { name: 'Greece', ISO: 'GR' },
  GL: { name: 'Greenland', ISO: 'GL' },
  GD: { name: 'Grenada', ISO: 'GD' },
  GP: { name: 'Guadeloupe', ISO: 'GP' },
  GU: { name: 'Guam', ISO: 'GU' },
  GT: { name: 'Guatemala', ISO: 'GT' },
  GG: { name: 'Guernsey', ISO: 'GG' },
  GN: { name: 'Guinea', ISO: 'GN' },
  GW: { name: 'Guinea-Bissau', ISO: 'GW' },
  GY: { name: 'Guyana', ISO: 'GY' },
  HT: { name: 'Haiti', ISO: 'HT' },
  HM: { name: 'Heard Island and McDonald Islands', ISO: 'HM' },
  HN: { name: 'Honduras', ISO: 'HN' },
  HK: { name: 'Hong Kong', ISO: 'HK' },
  HU: { name: 'Hungary', ISO: 'HU' },
  IS: { name: 'Iceland', ISO: 'IS' },
  IN: { name: 'India', ISO: 'IN' },
  ID: { name: 'Indonesia', ISO: 'ID' },
  IR: { name: 'Iran', ISO: 'IR' },
  IQ: { name: 'Iraq', ISO: 'IQ' },
  IE: { name: 'Ireland', ISO: 'IE' },
  IM: { name: 'Isle of Man', ISO: 'IM' },
  IL: { name: 'Israel', ISO: 'IL' },
  IT: { name: 'Italy', ISO: 'IT' },
  CI: { name: 'Ivory Coast', ISO: 'CI' },
  JM: { name: 'Jamaica', ISO: 'JM' },
  JP: { name: 'Japan', ISO: 'JP' },
  JE: { name: 'Jersey', ISO: 'JE' },
  JO: { name: 'Jordan', ISO: 'JO' },
  KZ: { name: 'Kazakhstan', ISO: 'KZ' },
  KE: { name: 'Kenya', ISO: 'KE' },
  KI: { name: 'Kiribati', ISO: 'KI' },
  XK: { name: 'Kosovo', ISO: 'XK' },
  KW: { name: 'Kuwait', ISO: 'KW' },
  KG: { name: 'Kyrgyzstan', ISO: 'KG' },
  LA: { name: 'Laos', ISO: 'LA' },
  LV: { name: 'Latvia', ISO: 'LV' },
  LB: { name: 'Lebanon', ISO: 'LB' },
  LS: { name: 'Lesotho', ISO: 'LS' },
  LR: { name: 'Liberia', ISO: 'LR' },
  LY: { name: 'Libya', ISO: 'LY' },
  LI: { name: 'Liechtenstein', ISO: 'LI' },
  LT: { name: 'Lithuania', ISO: 'LT' },
  LU: { name: 'Luxembourg', ISO: 'LU' },
  MO: { name: 'Macao', ISO: 'MO' },
  MG: { name: 'Madagascar', ISO: 'MG' },
  MW: { name: 'Malawi', ISO: 'MW' },
  MY: { name: 'Malaysia', ISO: 'MY' },
  MV: { name: 'Maldives', ISO: 'MV' },
  ML: { name: 'Mali', ISO: 'ML' },
  MT: { name: 'Malta', ISO: 'MT' },
  MH: { name: 'Marshall Islands', ISO: 'MH' },
  MQ: { name: 'Martinique', ISO: 'MQ' },
  MR: { name: 'Mauritania', ISO: 'MR' },
  MU: { name: 'Mauritius', ISO: 'MU' },
  YT: { name: 'Mayotte', ISO: 'YT' },
  MX: { name: 'Mexico', ISO: 'MX' },
  FM: { name: 'Micronesia', ISO: 'FM' },
  MD: { name: 'Moldova', ISO: 'MD' },
  MC: { name: 'Monaco', ISO: 'MC' },
  MN: { name: 'Mongolia', ISO: 'MN' },
  ME: { name: 'Montenegro', ISO: 'ME' },
  MS: { name: 'Montserrat', ISO: 'MS' },
  MA: { name: 'Morocco', ISO: 'MA' },
  MZ: { name: 'Mozambique', ISO: 'MZ' },
  MM: { name: 'Myanmar [Burma]', ISO: 'MM' },
  NA: { name: 'Namibia', ISO: 'NA' },
  NR: { name: 'Nauru', ISO: 'NR' },
  NP: { name: 'Nepal', ISO: 'NP' },
  NL: { name: 'Netherlands', ISO: 'NL' },
  NC: { name: 'New Caledonia', ISO: 'NC' },
  NZ: { name: 'New Zealand', ISO: 'NZ' },
  NI: { name: 'Nicaragua', ISO: 'NI' },
  NE: { name: 'Niger', ISO: 'NE' },
  NG: { name: 'Nigeria', ISO: 'NG' },
  NU: { name: 'Niue', ISO: 'NU' },
  NF: { name: 'Norfolk Island', ISO: 'NF' },
  KP: { name: 'North Korea', ISO: 'KP' },
  MK: { name: 'North Macedonia', ISO: 'MK' },
  MP: { name: 'Northern Mariana Islands', ISO: 'MP' },
  NO: { name: 'Norway', ISO: 'NO' },
  OM: { name: 'Oman', ISO: 'OM' },
  PK: { name: 'Pakistan', ISO: 'PK' },
  PW: { name: 'Palau', ISO: 'PW' },
  PS: { name: 'Palestine', ISO: 'PS' },
  PA: { name: 'Panama', ISO: 'PA' },
  PG: { name: 'Papua New Guinea', ISO: 'PG' },
  PY: { name: 'Paraguay', ISO: 'PY' },
  PE: { name: 'Peru', ISO: 'PE' },
  PH: { name: 'Philippines', ISO: 'PH' },
  PN: { name: 'Pitcairn Islands', ISO: 'PN' },
  PL: { name: 'Poland', ISO: 'PL' },
  PT: { name: 'Portugal', ISO: 'PT' },
  PR: { name: 'Puerto Rico', ISO: 'PR' },
  QA: { name: 'Qatar', ISO: 'QA' },
  CG: { name: 'Republic of the Congo', ISO: 'CG' },
  RO: { name: 'Romania', ISO: 'RO' },
  RU: { name: 'Russia', ISO: 'RU' },
  RW: { name: 'Rwanda', ISO: 'RW' },
  RE: { name: 'Réunion', ISO: 'RE' },
  BL: { name: 'Saint Barthélemy', ISO: 'BL' },
  SH: { name: 'Saint Helena', ISO: 'SH' },
  KN: { name: 'Saint Kitts and Nevis', ISO: 'KN' },
  LC: { name: 'Saint Lucia', ISO: 'LC' },
  MF: { name: 'Saint Martin', ISO: 'MF' },
  PM: { name: 'Saint Pierre and Miquelon', ISO: 'PM' },
  VC: { name: 'Saint Vincent and the Grenadines', ISO: 'VC' },
  WS: { name: 'Samoa', ISO: 'WS' },
  SM: { name: 'San Marino', ISO: 'SM' },
  SA: { name: 'Saudi Arabia', ISO: 'SA' },
  SN: { name: 'Senegal', ISO: 'SN' },
  RS: { name: 'Serbia', ISO: 'RS' },
  SC: { name: 'Seychelles', ISO: 'SC' },
  SL: { name: 'Sierra Leone', ISO: 'SL' },
  SG: { name: 'Singapore', ISO: 'SG' },
  SX: { name: 'Sint Maarten', ISO: 'SX' },
  SK: { name: 'Slovakia', ISO: 'SK' },
  SI: { name: 'Slovenia', ISO: 'SI' },
  SB: { name: 'Solomon Islands', ISO: 'SB' },
  SO: { name: 'Somalia', ISO: 'SO' },
  ZA: { name: 'South Africa', ISO: 'ZA' },
  KR: { name: 'South Korea', ISO: 'KR' },
  SS: { name: 'South Sudan', ISO: 'SS' },
  ES: { name: 'Spain', ISO: 'ES' },
  LK: { name: 'Sri Lanka', ISO: 'LK' },
  SD: { name: 'Sudan', ISO: 'SD' },
  SR: { name: 'Suriname', ISO: 'SR' },
  SJ: { name: 'Svalbard and Jan Mayen', ISO: 'SJ' },
  SZ: { name: 'Swaziland', ISO: 'SZ' },
  SE: { name: 'Sweden', ISO: 'SE' },
  CH: { name: 'Switzerland', ISO: 'CH' },
  SY: { name: 'Syria', ISO: 'SY' },
  ST: { name: 'São Tomé and Príncipe', ISO: 'ST' },
  TW: { name: 'Taiwan', ISO: 'TW' },
  TJ: { name: 'Tajikistan', ISO: 'TJ' },
  TZ: { name: 'Tanzania', ISO: 'TZ' },
  TH: { name: 'Thailand', ISO: 'TH' },
  TG: { name: 'Togo', ISO: 'TG' },
  TK: { name: 'Tokelau', ISO: 'TK' },
  TO: { name: 'Tonga', ISO: 'TO' },
  TT: { name: 'Trinidad and Tobago', ISO: 'TT' },
  TN: { name: 'Tunisia', ISO: 'TN' },
  TR: { name: 'Turkey', ISO: 'TR' },
  TM: { name: 'Turkmenistan', ISO: 'TM' },
  TC: { name: 'Turks and Caicos Islands', ISO: 'TC' },
  TV: { name: 'Tuvalu', ISO: 'TV' },
  UM: { name: 'U.S. Minor Outlying Islands', ISO: 'UM' },
  VI: { name: 'U.S. Virgin Islands', ISO: 'VI' },
  UG: { name: 'Uganda', ISO: 'UG' },
  UA: { name: 'Ukraine', ISO: 'UA' },
  AE: { name: 'United Arab Emirates', ISO: 'AE' },
  GB: { name: 'United Kingdom', ISO: 'GB' },
  US: { name: 'United States', ISO: 'US' },
  UY: { name: 'Uruguay', ISO: 'UY' },
  UZ: { name: 'Uzbekistan', ISO: 'UZ' },
  VU: { name: 'Vanuatu', ISO: 'VU' },
  VA: { name: 'Vatican City', ISO: 'VA' },
  VE: { name: 'Venezuela', ISO: 'VE' },
  VN: { name: 'Vietnam', ISO: 'VN' },
  WF: { name: 'Wallis and Futuna', ISO: 'WF' },
  EH: { name: 'Western Sahara', ISO: 'EH' },
  YE: { name: 'Yemen', ISO: 'YE' },
  ZM: { name: 'Zambia', ISO: 'ZM' },
  ZW: { name: 'Zimbabwe', ISO: 'ZW' },
  AX: { name: 'Åland', ISO: 'AX' },
}
